import React, { ReactNode } from 'react';

interface ButtonType {
  title: string | ReactNode;
  onClick: () => void;
  fullScreen?: boolean;
  disabled?: boolean;
  bgColor?: string;
  color?: string;
}

const Button = ({
  onClick,
  title,
  fullScreen = false,
  disabled = false,
  bgColor = '',
  color = ''
}: ButtonType): React.JSX.Element => {
  return (
    <button
      disabled={disabled}
      style={{
        background: disabled ? '#7ED7D4' : bgColor ? bgColor : '#009A96',
        padding: '16px 32px',
        width: fullScreen ? '100%' : 'fit-content',
        borderRadius: '12px',
        border: 'none',
        fontSize: '16px',
        color: color ? color : '#FFFFFF',
        fontWeight: '600',
        cursor: disabled ? 'not-allowed' : 'pointer'
      }}
      onClick={onClick}>
      {title}
    </button>
  );
};
export default Button;
