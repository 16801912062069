import React from 'react';
import { Outlet, useLocation } from 'react-router';
import { commitStr } from '../utils/commit';

const Layout = ({ isDarkMode }: { isDarkMode: boolean }) => {
  const router = useLocation();
  const pathName = router.pathname;

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', flex: 1 }}>
      <Outlet />
      <div
        style={{
          width: '100%',
          padding: '8px',
          fontSize: '10px',
          textAlign: 'center',
          position: 'fixed',
          bottom: pathName === '/' ? '80px' : '0px',
          backgroundColor: '#9a99991a',
          color: isDarkMode ? '#ffffff' : '#000000'
        }}>{`Version: ${commitStr}`}</div>
    </div>
  );
};

export default Layout;
