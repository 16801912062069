import React from 'react';
import assets from '../assets';

const Success = () => {
  const isAndroid = /Android/.test(navigator.userAgent);
  const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'green'
      }}>
      {isAndroid && (
        <div>
          <img src={assets.ANDROID_LOADER_ICON} alt={'Android loader Icon'} className="loader" />
        </div>
      )}
      {isIOS && (
        <div>
          <img src={assets.IOS_LOADER_ICON} alt={'Android loader Icon'} className="loader" />
        </div>
      )}
    </div>
  );
};

export default Success;
