const Divider = ({ text, isDarkMode }: { text: string; isDarkMode: boolean }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '0px',
        borderBottom: isDarkMode ? '1px solid white' : '1px solid black',
        textAlign: 'center'
      }}>
      <span
        style={{
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: isDarkMode ? '#1a1818' : '#E9EDF5',
          padding: '0 10px',
          color: isDarkMode ? '#ffffff' : '#434854'
        }}>
        {text}
      </span>
    </div>
  );
};

export default Divider;
