import NotificationIcon from './icons/notifications.svg';
import PaymentCardIcon from './icons/paymentCard.svg';
import WizzLogoIcon from './icons/wizzLogo.svg';
import GoogleIcon from './icons/google.svg';
import UserPhoto from './images/userpic.svg';
import AppleIcon from './icons/apple.svg';
import AppleDarkIcon from './icons/appleDark.svg';
import AndroidLoaderIcon from './images/android_loader.png';
import IosLoaderIcon from './images/ios_loader.png';

const assets = {
  NOTIFICATION_ICON: NotificationIcon,
  PAYMENT_CARD_ICON: PaymentCardIcon,
  WIZZ_LOGO_ICON: WizzLogoIcon,
  USER_PHOTO: UserPhoto,
  GOOGLE_ICON: GoogleIcon,
  APPLE_ICON: AppleIcon,
  APPLE_DARK_ICON: AppleDarkIcon,
  ANDROID_LOADER_ICON: AndroidLoaderIcon,
  IOS_LOADER_ICON: IosLoaderIcon
};
export default assets;
