import { CardNumber, Cvv, ExpiryDate, Frames } from 'frames-react';
import { Dispatch, SetStateAction, useState } from 'react';

declare global {
  interface Window {
    webkit: any;
    messageHandler: any;
  }
}

const CheckoutCardDetail = ({
  setIsValid,
  isDarkMode
}: {
  setIsValid: Dispatch<SetStateAction<boolean>>;
  isDarkMode: boolean;
}): React.JSX.Element => {
  const isAndroid = /Android/.test(navigator.userAgent);
  const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);
  const [inputFocus, setInputFocus] = useState<'card-number' | 'expiry-date' | 'cvv' | null>(null);
  const [isEmpty, setIsEmpty] = useState<{
    'card-number': boolean;
    'expiry-date': boolean;
    cvv: boolean;
  }>({
    'card-number': true,
    'expiry-date': true,
    cvv: true
  });

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        padding: '0px 16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px'
      }}>
      <p
        style={{
          fontSize: '24px',
          fontWeight: '590',
          margin: '24px 0px',
          color: isDarkMode ? '#ffffff' : '#09181A'
        }}>
        Add card details
      </p>
      <Frames
        paymentMethodChanged={event => {
          const cardIconElement = document.getElementById(
            'payment-method-logo'
          ) as HTMLImageElement;

          if (cardIconElement) {
            cardIconElement.src =
              event?.paymentMethod === 'Visa'
                ? 'https://js.checkout.com/framesv2/img/visa.svg'
                : 'https://js.checkout.com/framesv2/img/mastercard.svg';
          }
        }}
        config={{
          schemeChoice: true,
          publicKey: process.env.REACT_APP_CHECKOUT_PUBLIC_KEY || '',
          style: {
            base: {
              fontSize: '16px',
              fontWeight: '400',
              color: '#090E1D',
              backgroundColor: '#FFFFFF',
              padding: '8px',
              borderRadius: '12px',
              height: '52px'
            },
            focus: {
              paddingTop: '20px'
            },
            valid: {
              paddingTop: '20px'
            },
            invalid: {
              paddingTop: '20px'
            },
            placeholder: {
              base: {
                fontSize: '14px',
                fontWeight: '400',
                color: '#434854'
              },
              focus: {
                color: 'transparent'
              }
            }
          }
        }}
        frameValidationChanged={event => {
          setIsEmpty(preValue => {
            return { ...preValue, [event.element]: event.isEmpty };
          });
        }}
        frameFocus={event => {
          setInputFocus(event?.element);
        }}
        frameBlur={() => {
          setInputFocus(null);
        }}
        cardValidationChanged={validation => {
          setIsValid(validation?.isValid);
        }}
        cardTokenized={e => {
          isIOS &&
            window?.webkit?.messageHandlers?.messageHandler?.postMessage(
              JSON.stringify({
                isLoading: false,
                token: e.token,
                error: null
              })
            );
          isAndroid &&
            window?.postMessage(
              JSON.stringify({
                isLoading: false,
                token: e.token,
                error: null
              })
            );
        }}
        cardTokenizationFailed={e => {
          isIOS &&
            window?.webkit?.messageHandlers?.messageHandler?.postMessage(
              JSON.stringify({
                isLoading: false,
                token: null,
                error: e.message
              })
            );
          isAndroid &&
            window?.postMessage(
              JSON.stringify({
                isLoading: false,
                token: null,
                error: e.message
              })
            );
        }}>
        <div style={{ position: 'relative' }}>
          {(inputFocus === 'card-number' || !isEmpty['card-number']) && (
            <p
              style={{
                fontSize: '12px',
                fontWeight: '400',
                marginBottom: '8px',
                color: '#687082',
                position: 'absolute',
                top: '8px',
                left: '8px',
                zIndex: 2
              }}>
              Card Number
            </p>
          )}
          <CardNumber className="card-number-frame" />
        </div>
        <div style={{ display: 'flex', gap: '10px' }}>
          <div style={{ flex: 1, position: 'relative' }}>
            {(inputFocus === 'expiry-date' || !isEmpty['expiry-date']) && (
              <p
                style={{
                  fontSize: '12px',
                  fontWeight: '400',
                  marginBottom: '8px',
                  color: '#687082',
                  position: 'absolute',
                  top: '8px',
                  left: '8px',
                  zIndex: 2
                }}>
                Month/Year
              </p>
            )}
            <ExpiryDate />
          </div>
          <div style={{ flex: 1, position: 'relative' }}>
            {(inputFocus === 'cvv' || !isEmpty['cvv']) && (
              <p
                style={{
                  fontSize: '12px',
                  fontWeight: '400',
                  marginBottom: '8px',
                  color: '#687082',
                  position: 'absolute',
                  top: '8px',
                  left: '8px',
                  zIndex: 2
                }}>
                CVV
              </p>
            )}
            <Cvv />
          </div>
        </div>
      </Frames>
    </div>
  );
};
export default CheckoutCardDetail;
