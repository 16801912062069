import React, { useLayoutEffect, useState, useCallback } from 'react';
import { Frames } from 'frames-react';
import Button from '../components/Button';
import CheckoutCardDetail from '../forms/CheckoutCardDetail';

const useDocumentHeight = () => {
  const getHeight = useCallback(
    () => (window.visualViewport ? window.visualViewport.height : window.innerHeight),
    []
  );
  const [height, setHeight] = useState<number>(getHeight());

  useLayoutEffect(() => {
    const handleResize = (e: Event) => {
      setHeight(getHeight());
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    window.visualViewport?.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
      window.visualViewport?.removeEventListener('resize', handleResize);
    };
  }, [getHeight]);

  return height - 85;
};

const CardDetail = ({ isDarkMode }: { isDarkMode: boolean }): React.JSX.Element => {
  const [isValid, setIsValid] = useState<boolean>(false);
  const isAndroid = /Android/.test(navigator.userAgent);
  const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);

  return (
    <>
      <CheckoutCardDetail setIsValid={setIsValid} isDarkMode={isDarkMode} />
      <div
        style={{
          width: '100%',
          padding: '16px',
          position: 'absolute',
          top: useDocumentHeight(),
          zIndex: 99999
        }}>
        <Button
          onClick={() => {
            isIOS &&
              window?.webkit?.messageHandlers?.messageHandler?.postMessage(
                JSON.stringify({
                  isLoading: true,
                  token: null,
                  error: null
                })
              );
            isAndroid &&
              window?.postMessage(
                JSON.stringify({
                  isLoading: true,
                  token: null,
                  error: null
                })
              );
            Frames.submitCard();
          }}
          title="Add Money"
          disabled={!isValid}
          fullScreen
        />
      </div>
    </>
  );
};
export default CardDetail;
