import assets from '../assets';
import Button from '../components/Button';

const SuccessConfirm = (): React.JSX.Element => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '32px',
        height: '100%',
        flexDirection: 'column'
      }}>
      <img
        src={assets.NOTIFICATION_ICON}
        alt={'Notification Icon'}
        style={{ height: '145px', width: '140px' }}
      />
      <h2 style={{ fontSize: '24px', color: '#009A96' }}>3DS</h2>
      <Button onClick={() => {}} title="Next" />
    </div>
  );
};
export default SuccessConfirm;
