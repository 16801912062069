import React from 'react';
import { Frames } from 'frames-react';

import CheckoutCardDetail from '../../forms/CheckoutCardDetail';
import Button from '../../components/Button';
import { EnterCardDetailsProps } from './addMoneyByLink.types';

const EnterCardDetails: React.FC<EnterCardDetailsProps> = ({ isValid, setIsValid, isDarkMode }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '24px'
      }}>
      <CheckoutCardDetail setIsValid={setIsValid} isDarkMode={isDarkMode} />
      <div style={{ width: '100%', padding: '16px' }}>
        <Button
          onClick={() => Frames.submitCard()}
          title="Pay by card"
          disabled={!isValid}
          fullScreen
        />
      </div>
    </div>
  );
};

export default EnterCardDetails;
