import React, { useState } from 'react';
// import axios from 'axios';
import GooglePayButton from '@google-pay/button-react';

import assets from '../assets';
import Divider from '../components/Divider';
import ApplePayButton from '../components/ApplepayButton';
import EnterCardDetails from '../sections/AddMoneyByLink/EnterCardDetails';

declare global {
  interface Window {
    ApplePaySession: any; // Add this line to define ApplePaySession
  }
}

const AddMoneyByLink = ({ isDarkMode }: { isDarkMode: boolean }) => {
  const [isEnterCardDetails, setIsEnterCardDetails] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>('0');
  const [isValid, setIsValid] = useState<boolean>(false);
  const isAndroid = /Android/.test(navigator.userAgent);

  const handleAmountChange = (event: { target: { value: string } }) => {
    const value = event.target.value;
    const regex = /^\d{0,11}(\.\d{0,2})?$/;
    if (regex.test(value)) {
      setAmount(value.replace(/^0+/, ''));
    }
  };

  const handleAmountFocus = () => {
    if (amount === '0') {
      setAmount('');
    }
  };

  // const getCheckoutToken = (payload: { type: string; token: string }) => {
  //   const body = {
  //     type: payload?.type,
  //     token_data: JSON.parse(payload?.token)
  //   };

  //   const response = axios.post('https://api.sandbox.checkout.com/tokens', body, {
  //     headers: {
  //       Authorization: `${process.env.REACT_APP_CHECKOUT_PUBLIC_KEY}`
  //     }
  //   });
  // };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#009A96',
        width: '100%',
        height: '100%'
      }}>
      <div style={{ padding: '16px', width: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <img
            src={assets.WIZZ_LOGO_ICON}
            alt={'Wizz logo Icon'}
            style={{ height: '28px', width: '68px' }}
          />
          <div></div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            margin: '32px 0px 20px'
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              gap: '8px'
            }}>
            <p style={{ fontSize: '16px', fontWeight: '510', color: '#FFFFFF' }}>Recipient</p>
            <p style={{ fontSize: '28px', fontWeight: '700', color: '#FFFFFF' }}>Ram Taneja</p>
          </div>
          <div style={{ borderRadius: '8px', overflow: 'hidden' }}>
            <img
              src={assets.USER_PHOTO}
              alt={'User Profile'}
              style={{ width: '60px', height: '60px' }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          backgroundColor: isDarkMode ? '#1a1818' : '#E9EDF5',
          borderRadius: '12px 12px 0px 0px',
          padding: '28px 0px'
        }}>
        <div style={{ width: '100%', padding: '0px 16px' }}>
          <p
            style={{
              fontSize: '14px',
              fontWeight: '400',
              color: isDarkMode ? '#ffffff' : '#434854',
              width: '100%',
              marginBottom: '16px'
            }}>
            Money amount
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              borderBottom: '1px solid #D8DCE4'
            }}>
            <p
              style={{
                fontWeight: '590',
                fontSize: '38px',
                color: isDarkMode ? '#ffffff' : '#09181A'
              }}>
              AED
            </p>
            <input
              type="number"
              value={amount}
              onChange={handleAmountChange}
              onFocus={handleAmountFocus}
              id="amount_inputfield"
              pattern="\d*"
              style={{ color: isDarkMode ? '#ffffff' : '#09181A' }}
            />
          </div>
          <input
            type="text"
            placeholder="Comment"
            id="commnet_inputfield"
            style={{ margin: '24px 0px' }}
          />
          {isAndroid ? (
            <GooglePayButton
              environment="TEST" // Use "TEST" during development and "PRODUCTION" for live environment
              paymentRequest={{
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: [
                  {
                    type: 'CARD',
                    parameters: {
                      allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                      allowedCardNetworks: ['AMEX', 'DISCOVER', 'JCB', 'MASTERCARD', 'VISA']
                    },
                    tokenizationSpecification: {
                      type: 'PAYMENT_GATEWAY',
                      parameters: {
                        gateway: 'checkoutltd',
                        gatewayMerchantId: `${process.env.REACT_APP_GOOGLE_PAY_GATEWAY_MERCHANT_ID}`
                      }
                    }
                  }
                ],
                merchantInfo: {
                  merchantId: `${process.env.REACT_APP_GOOGLE_PAY_GATEWAY_MERCHANT_ID}`, // Replace with our own merchant ID
                  merchantName: 'Example Merchant'
                },
                transactionInfo: {
                  totalPriceStatus: 'FINAL',
                  totalPrice: '1.00',
                  currencyCode: 'USD',
                  countryCode: 'US'
                }
              }}
              onLoadPaymentData={(paymentData: any) => {
                console.log('PAYMENT DATA ====>>>>:', paymentData);

                // Process the payment data and handle the response
                // getCheckoutToken(paymentData?.paymentMethodData?.tokenizationData);
              }}
              onError={reason => {
                console.log('ERROR REASON ====>>>>', reason);
              }}
              buttonColor={isDarkMode ? 'white' : 'black'}
              buttonType="pay"
              style={{ width: '100%', height: '52px' }}
            />
          ) : (
            <ApplePayButton isDarkMode={isDarkMode} />
          )}
          <div style={{ padding: '32px 0px 24px 0px' }}>
            <Divider text="Or enter card details" isDarkMode={isDarkMode} />
          </div>
        </div>

        {isEnterCardDetails ? (
          <EnterCardDetails isValid={isValid} setIsValid={setIsValid} isDarkMode={isDarkMode} />
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: 'fit-content',
              height: '40px',
              gap: '12px',
              cursor: 'pointer',
              borderBottom: '1px solid #009A96'
            }}
            onClick={() => setIsEnterCardDetails(true)}>
            <img
              src={assets.PAYMENT_CARD_ICON}
              alt={'Payment card Icon'}
              style={{ height: '18px', width: '14px' }}
            />
            <h2 style={{ fontSize: '16px', fontWeight: '500', color: '#009A96' }}>
              Enter card details
            </h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddMoneyByLink;
