import React, { useEffect, useState } from 'react';
import axios from 'axios';
import assets from '../assets';
import Button from '../components/Button';
import { useLocation, useSearchParams } from 'react-router-dom';

export type SenderDetailsType = {
  senderName: string;
  amount: number;
  profilePicture: string;
};

const PostponedPreviewPayment = () => {
  const SENDER_NAME = 'Ram Taneja';
  const AMOUNT = '27612.70';
  const isAndroid = /Android/.test(navigator.userAgent);
  const ANDROID_APP_LINK =
    'https://play.google.com/store/apps/details?id=com.vaibhavkalpe.android.khatabook';
  const IOS_APP_LINK = 'https://apps.apple.com/in/app/piccollage-photo-video-editor/id448639966';

  const [searchParams] = useSearchParams();
  const isExpired = searchParams?.get('expired') === 'true';
  const location = useLocation();
  const uid = location.pathname?.split('/').pop();

  const [senderDetails, setSenderDetails] = useState<SenderDetailsType>({
    senderName: '',
    amount: 0,
    profilePicture: ''
  });

  console.log('senderDetails ====>>>>', senderDetails);

  const getSenderDetails = async () => {
    await axios
      .get(`https://ibs.wizzpay.dev/api/v1/wizz/orders/postponed?uid=${uid}`)
      .then(response => {
        setSenderDetails({
          senderName: response?.data?.financial?.payerName,
          amount: response?.data?.financial?.amount,
          profilePicture: response?.data?.userModel?.icon?.url
        });
      })
      .catch(error => console.error(error));
  };

  useEffect(() => {
    getSenderDetails().catch(error => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#009A96',
        width: '100%',
        height: '100%'
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: isExpired ? '100%' : '75%',
          gap: '16px'
        }}>
        {isExpired ? (
          <span
            style={{
              fontFamily: 'sans-serif',
              fontWeight: '590',
              fontSize: '18px',
              color: '#FFFFFF',
              textAlign: 'center',
              width: '70%'
            }}>
            Unfortunately, the transfer has been expired
          </span>
        ) : (
          <>
            <div style={{ borderRadius: '8px', overflow: 'hidden' }}>
              <img
                src={assets.USER_PHOTO}
                alt={'User Profile'}
                style={{ width: '60px', height: '60px' }}
              />
            </div>
            <span style={{ fontWeight: '590', fontSize: '18px', color: '#FFFFFF' }}>
              {`Transfer from ${SENDER_NAME}`}
            </span>
          </>
        )}
        <div
          style={{ display: 'flex', alignItems: 'baseline', gap: '4px' }}
          className={isExpired ? 'horizontal-line' : ''}>
          <span style={{ fontWeight: '590', fontSize: '24px', color: '#FFFFFF' }}>₹</span>
          <span style={{ fontWeight: '860', fontSize: '38px', color: '#FFFFFF' }}>
            {AMOUNT?.split('.')[0]?.replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
          </span>
          <span style={{ fontWeight: '590', fontSize: '18px', color: '#FFFFFF' }}>
            {`.${AMOUNT?.split('.')[1]}`}
          </span>
        </div>
      </div>
      <div
        style={{
          position: 'fixed',
          bottom: '0',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          backgroundColor: '#E9EDF5',
          borderRadius: '12px 12px 0px 0px',
          padding: isExpired ? '32px 16px' : '94px 16px 32px',
          gap: '10px'
        }}>
        {!isExpired && (
          <p style={{ fontWeight: '510', fontSize: '12px', color: '#687082' }}>
            Download WizzPay application to receive a transfer
          </p>
        )}
        <Button
          onClick={() => {
            window.location.href = isAndroid ? ANDROID_APP_LINK : IOS_APP_LINK;
          }}
          title={isExpired ? 'Open App Store' : 'Download on the App Store'}
          fullScreen
        />
      </div>
    </div>
  );
};

export default PostponedPreviewPayment;
