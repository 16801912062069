import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router';
import CardDetail from './pages/CardDetail';
import SuccessConfirm from './pages/SuccessConfirm';
import AddMoneyByLink from './pages/AddMoneyByLink';

import './App.css';
import Layout from './layout';
import PostponedPreviewPayment from './pages/PostponedPreviewPayment';
import Success from './pages/Success';
import Failure from './pages/Failure';

export default function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setIsDarkMode(darkModeMediaQuery.matches);

    const handleThemeChange = (e: { matches: boolean | ((prevState: boolean) => boolean) }) => {
      setIsDarkMode(e.matches);
      if (e.matches) {
        document.body.style.backgroundColor = '#1a1818';
      } else {
        document.body.style.backgroundColor = '#E9EDF5';
      }
    };
    darkModeMediaQuery.addEventListener('change', handleThemeChange);

    if (darkModeMediaQuery.matches) {
      document.body.style.backgroundColor = '#1a1818';
    } else {
      document.body.style.backgroundColor = '#E9EDF5';
    }

    return () => {
      darkModeMediaQuery.removeEventListener('change', handleThemeChange);
    };
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Layout isDarkMode={isDarkMode} />}>
        <Route path="/" element={<CardDetail isDarkMode={isDarkMode} />} />
        <Route path="/3ds" element={<SuccessConfirm />} />
        <Route path="/add-money-by-link" element={<AddMoneyByLink isDarkMode={isDarkMode} />} />
        <Route path="/postponed-payment-preview" element={<PostponedPreviewPayment />} />
        <Route path="/postponed-payment-preview/:uid" element={<PostponedPreviewPayment />} />
        <Route path="/success" element={<Success />} />
        <Route path="/failure" element={<Failure />} />
      </Route>
    </Routes>
  );
}
