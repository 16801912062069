import React from 'react';
import axios from 'axios';
import Button from '../Button';
import assets from '../../assets';

export interface PaymentTokenData {
  paymentData: {
    version: string;
    data: string;
    signature: string;
    header: {
      ephemeralPublicKey: string;
      publicKeyHash: string;
      transactionId: string;
    };
  };
}

const ApplePayButton = ({ isDarkMode }: { isDarkMode: boolean }) => {
  const applePayButtonTitle = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        gap: '12px',
        cursor: 'pointer'
      }}>
      <img
        src={isDarkMode ? assets.APPLE_DARK_ICON : assets.APPLE_ICON}
        alt={'Apple Icon'}
        style={{ height: '17px', width: '14px' }}
      />
      <p style={{ fontSize: '16px', fontWeight: '590', color: isDarkMode ? '#090E1D' : '#ffffff' }}>
        Apple pay
      </p>
    </div>
  );

  const validateTheSession = (
    theValidationURL: string,
    callback: { (merchantSession: any): void; (arg0: any): any }
  ) => {
    axios
      .post(
        'https://apple-pay-be.vercel.app/api/validateSession',
        { appleUrl: theValidationURL },
        { headers: { 'Access-Control-Allow-Origin': '*' } }
      )
      .then(response => {
        callback(response?.data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const pay = async (paymentToken: PaymentTokenData, callback: (arg0: any) => any) => {
    axios
      .post(
        'https://apple-pay-be.vercel.app/api/pay',
        { token: paymentToken },
        { headers: { 'Access-Control-Allow-Origin': '*' } }
      )
      .then(response => callback(response?.data))
      .catch(error => console.error(error));
  };

  const handleApplePayRequest = async () => {
    if (window.ApplePaySession) {
      const paymentRequest = {
        countryCode: 'US',
        currencyCode: 'USD',
        supportedNetworks: ['visa', 'masterCard', 'amex'],
        merchantCapabilities: ['supports3DS'],
        total: { label: 'Total', amount: '10.00' }
      };

      const session = await new window.ApplePaySession(1, paymentRequest);
      session.begin();

      session.onvalidatemerchant = (event: any) => {
        // Perform server-side validation with checkout.com and provide the merchant session identifier in the callback
        const theValidationURL = event?.validationURL;
        validateTheSession(theValidationURL, merchantSession => {
          session.completeMerchantValidation(merchantSession);
        });
      };

      session.onpaymentauthorized = (event: { payment: { token: PaymentTokenData } }) => {
        const paymentToken = event.payment.token;
        // Process the payment token on server and complete the payment on success or failure
        pay(paymentToken, outcome => {
          if (outcome) {
            session.completePayment(session.STATUS_SUCCESS);
          } else {
            session.completePayment(session.STATUS_FAILURE);
          }
        }).catch(error => console.error(error));
      };
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <Button
        onClick={handleApplePayRequest}
        title={applePayButtonTitle}
        bgColor={isDarkMode ? '#ffffff' : '#090E1D'}
        fullScreen
      />
    </div>
  );
};

export default ApplePayButton;
